<template>
  <sdPageHeader title="Edit Participants"> </sdPageHeader>
  <Main>
    <sdCards headless>
      <a-form :hideRequiredMark="false" ref="formRef" :model="form" :rules="formRules">
        <a-row :gutter="25">
          <a-col :lg="12" :xs="24">
            <a-form-item name="first_name" label="First Name">
              <a-input
                v-model:value="form.first_name"
                placeholder="Enter First name"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-item name="last_name" label="Last Name">
              <a-input
                v-model:value="form.last_name"
                placeholder="Enter Last name"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-item name="email" label="Email">
              <a-input
                type="email"
                v-model:value="form.email"
                placeholder="Enter Email"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-item name="phone" label="Phone">
              <a-input v-model:value="form.phone" placeholder="Enter Phone" />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-item name="address" label="Address">
              <a-input
                v-model:value="form.address"
                placeholder="Enter Address"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-item name="city" label="City">
              <a-input v-model:value="form.city" placeholder="Enter City" />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-item name="zip_code" label="Zipcode">
              <a-input
                v-model:value="form.zip_code"
                placeholder="Enter Zipcode"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <div class="custom_select_selector">
              <a-form-item label="Countries">
                <a-select
                  show-search
                  placeholder="Select countries"
                  option-filter-prop="label"
                  :filter-option="filterOption"
                  v-model:value="form.country"
                  @change="handleChange"
                >
                  <a-select-option
                    v-for="country of countries"
                    :key="country.label"
                    :value="country.value"
                    >{{ country.label }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </div>
          </a-col>
          <a-col :lg="24" :xs="24">
            <sdButton @click="onSubmitPress" type="primary" class="pull-right">
              Save
            </sdButton>
          </a-col>
        </a-row>
      </a-form>
    </sdCards>
  </Main>
</template>
<script>
import { API } from "@/services/api";
import { Main } from "../../styled";
import Notification from "@/services/Notification";

export default {
  name: "setupParticipant",
  components: { Main },
  computed: {
    countries: function () {
      return this.$store.getters.countries;
    },
    formRef: function() {
      return this.$refs.formRef;
    }
  },
  data() {
    return {
      fetching: true,
      subscriber: {},
      campaign_id: '',
      subscriber_id: '',
      form: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        zip_code: "",
        country: "",
      },
      formRules: {
        first_name: [{
          required: true, trigger: 'blur',
          message: 'Please enter first name',
        }],
        last_name: [{
          required: true, trigger: 'blur',
          message: 'Please enter last name',
        }],
        email: [{
          required: true, trigger: 'blur',
          message: 'Please enter email',
        }],
        phone: [{
          required: true, trigger: 'blur',
          message: 'Please enter phone',
        }],
        address: [{
          required: true, trigger: 'blur',
          message: 'Please enter address',
        }],
        city: [{
          required: true, trigger: 'blur',
          message: 'Please enter address',
        }],
        zip_code: [{
          required: true, trigger: 'blur',
          message: 'Please enter address',
        }],
      },
    };
  },
  methods: {
    async onSubmitPress() {
      try {
        await this.formRef.validate();
        const fData = new FormData();
        for (const [key, value] of Object.entries(this.form)) {
          if (key) {
            fData.append(key, value);
          }
        }
        fData.append("campaign_id",this.campaign_id);
        fData.append("subscriber_id",this.subscriber_id);
        const dataRes = await API.commitSubacribers(fData);
        const { message, status } = dataRes.data;
        
        if (message) {
          Notification.show(message, {
            type: status ? "success" : "error",
            title: status ? "Success" : "Error",
          });
        }
        // if(status) {
        //   this.$router.go(-1);
        // }
      } catch (error) {
        Notification.show(error.message, {type: "error",title: "Error"});
      }
    },
    async getSubscriberDetails() {
      try {
        const { subscriber_id = "" } = this.$route.params;
        const res = await API.getSubacriberDetail({params: {
          id: subscriber_id,
        }});
        this.subscriber_id = subscriber_id;

        const { data, message, status } = res?.data ?? {};
        if (status) {
          this.subscriber = data;
          const {first_name = "", last_name, email, phone, address, city, country, zip_code,campaign_id
          } = data

          this.form = {
              ...this.form,first_name,last_name, email , phone, address, city, country, zip_code
          };
          this.campaign_id = campaign_id;

        } else if(!status && message) {
          Notification.show(message, {type: "error",title: "Error"});
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, {type: "error",title: "Error"});
        }
      } finally {
        this.fetching = false;
      }
    },
    filterOption(input, option) {
      return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    handleChange(value) {
      this.form.country = value;
    },
  },
  created() {
    this.getSubscriberDetails();
  }
};
</script>
